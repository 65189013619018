
        @import 'styles/mixins.scss';
    
@import "@styles/sassVariables";
.featuredBlogPostsWrapper {
    width: 100%;
    max-width: 1295px;
}
.post {
    background: #ffffff;
    transition: all 0.2s linear;
    color: black;
    box-shadow: 0px 0px 18px -3px rgba(0, 0, 0, 0.15);
    img {
        transition: 0.2s linear;
    }

    &:hover {
        text-decoration: none;
        box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.05);

        img {
            transform: scale(1.1);
            transition: all 0.2s linear;
        }
    }
    .more {
        color: $linkColor;
        position: relative;
        &::before {
            position: absolute;
            content: "";
            width: 100%;
            height: 2px;
            border-radius: 99px;
            bottom: 0;
            left: 0;
            transform: scaleX(0);
            transform-origin: bottom right;
            transition: transform 0.25s ease-out;
            background: $linkColor;
        }
    }
    &:hover {
        .more {
            &::before {
                transform: scaleX(1);
                transform-origin: bottom left;
            }
        }
    }
}
.postWrapper {
    flex: 0 0 100%;
    @media screen and (min-width: 730px) {
        flex: 0 0 50%;
    }
    @media screen and (min-width: 1048px) {
        flex: 0 0 33.333333%;
    }
}
.postImg {
    width: 100%;
}
.postTitleBlock {
    height: 40px;
    overflow: hidden;

    @include line-clamp(2);

    @include lg {
        height: 56px;
    }
}
