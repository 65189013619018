
        @import 'styles/mixins.scss';
    
@import "@styles/sassVariables";
.borderStyle {
    outline: none;
    border-radius: 8px;
    height: 50px;

    cursor: pointer;
}
.borderRadiusStyle {
    border-radius: 8px 8px 0 0;
}
.borderStyleDisabled {
    outline: none;
    border: none;
    cursor: not-allowed;
    color: #757c87;
}
.hoverStyle:hover {
    background-color: #84c5f7;
    color: white;
}
.compareStyle {
    background-color: #1693f0;
    // background-color: #84c5f7;
    color: white;
    @include lg {
        display: none;
    }
}
.inputStyle {
    min-height: 30px;
    // border: 1px solid #ccc;
    outline: none;
    font-family: "Mulish", sans-serif;
    padding: 6px 10px;
    border-bottom: 1px solid $defaultBorderColor;
    z-index: 999999999999;
    width: 100%;

    &:focus-visible {
        outline: none !important;
    }
}
.compareStyleDisabled {
    background-color: #84c5f7;
    color: white;
    @include lg {
        display: none;
    }
}
.iconPointer {
    pointer-events: none;
}
