
        @import 'styles/mixins.scss';
    
.topVendorsArrows {
    top: 43px;
    @media screen and (min-width: 1048px) {
        top: 66px;
    }
}

.topVendorsContainer {
    z-index: 0 !important;
    background: linear-gradient(180deg, #18336a 0%, #0e2450 100%);
    max-width: 100%;
}
