
        @import 'styles/mixins.scss';
    
.arrow {
    svg {
        color: black;
        &:hover {
            color: #2b93f0;
        }
    }

    &:hover {
        cursor: pointer;
    }
}

.disabled {
    cursor: default !important;
    pointer-events: none !important;
    svg {
        color: lightgray;
    }
}

.selected {
    color: #2b93f0;
    border: 1px solid #2b93f0;
    background-color: #2b94f014;
    width: 2rem;
    height: 2rem;
    border-radius: 5px;
}
