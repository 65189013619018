
        @import 'styles/mixins.scss';
    
.selected {
    position: relative;
    &::before {
        position: absolute;
        content: "";
        width: 100%;
        height: 3px;
        bottom: 0;
        left: 0;
        background-color: #1693f0;
        animation: grow 0.2s ease-in forwards;
    }
   
    .arrow-up {
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid #1693f0;
    }
}

@keyframes grow {
    0% {
        transform: scaleX(0);
        visibility: visible;
    }
    100% {
        transform: scaleX(1);
        visibility: visible;
    }
}