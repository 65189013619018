
        @import 'styles/mixins.scss';
    
.steps {
    max-width: 1098px;
    margin: 0 auto;
}
.step {
    background-color: #f2f8ff;
    width: 100%;
    max-width: 330px;

    @media screen and (min-width: 1049px) {
        min-height: 272px;
    }
}
html[lang="ar"] .stepImage {
    transform: translateY(-86%) translateX(-160px);
}
.stepImage {
    transform: translateY(-80%) translateX(180px);
    position: absolute;
    width: 110px;
    @media screen and (min-width: 1048px) {
        width: auto;
        transform: translateY(-86%) translateX(160px);
    }
}
.stepContent {
    @media screen and (min-width: 1049px) {
        min-height: 100px;
    }
}
.disableStep {
    // opacity: 0.5;
}
.paddingStyle {
    padding-bottom: 2.5rem !important;
    background-color: red;
    @include lg {
        padding-bottom: 1rem;
    }
}
.buttonStyle:disabled:hover {
    background: #84c5f7 !important;
}

.orangeDotWrapper {
    position: absolute;
    z-index: 0;
    margin: auto;
    left: 50%;
    top: -75px;
    width: 89px;
    height: 89px;
    margin-left: -200px;
    
    svg{
        display: block;
        width: 100%;
    }


    @media screen and (min-width: 1048px) {
        left: -40px;
        top: 88px;
        width: 185px;
        height: 184px;
        margin-left: 0;
    }
}