
        @import 'styles/mixins.scss';
    
.shadowStyle {
    width: 80px;
    height: 107px;
    background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0.2),
        rgba(255, 255, 255, 1)
    );
    pointer-events: none;
    // box-shadow: 1px 1px 3px 2px rgb(203, 203, 203) inset;
    // border: 1px solid;
    position: absolute;
    top: 105px;
    right: 0;
    @include sm {
        top: 109px;
    }
    @include lg {
        display: none;
    }
}
