
        @import 'styles/mixins.scss';
    
@import "@styles/sassVariables";
.container {
    scrollbar-color: #f1f1f1;
    scrollbar-width: thin;
    .borderStyle {
        outline: none;
        border-radius: 8px;
        height: 50px;

        cursor: pointer;
    }
    .borderRadiusStyle {
        border-radius: 8px;
    }
    .borderStyle:disabled {
        outline: none;
        border: none;
    }
    .hoverStyle:hover {
        background-color: #84c5f7;
        color: white;
    }

    .input {
        min-height: 30px;
        // border: 1px solid #ccc;
        outline: none !important;
        font-family: "Mulish", sans-serif;
        padding: 6px 10px;
        border-bottom: 1px solid $defaultBorderColor;
        z-index: 999999999999;
        width: 100%;

        &:focus-visible {
            outline: none !important;
        }
    }
    .iconStyle {
        svg {
            width: 16px;
            height: 16px;
        }

        display: block;

        padding: 5px;
    }
    .iconStyle:hover {
        path {
            fill: #f9bb21;
        }
    }
}
.iconPointer {
    pointer-events: none;
}
