
        @import 'styles/mixins.scss';
    
.hoverStyle:hover {
    background-color: #84c5f7;
    color: white;
}
.colors {
    color: #58616d;
}
/****************/
.checkBoxStyle {
    position: relative;

    input {
        visibility: hidden;
        user-select: none;
        position: absolute;
        left: -100rem;
        &:checked + label .box svg path {
            stroke-dashoffset: 0;
        }
        &:checked + label .box {
            background-color: white;
        }
    }
    label {
        display: flex;
        align-items: center;
        min-height: 40px;
        .box {
            display: block;
            border: 1px solid black;
            width: 13px;
            height: 13px;
            // border-radius: 4px;
            cursor: pointer;
            transition: all 0.2s ease;
            margin-right: 15px;
            padding: 2px;

            svg {
                pointer-events: none;
                path {
                    fill: none;
                    stroke: white;
                    stroke-width: 6px;
                    stroke-linecap: round;
                    stroke-linejoin: round;
                    stroke-dasharray: 100;
                    stroke-dashoffset: 101;
                    transition: all 350ms cubic-bezier(1, 0, 0.37, 0.91);
                }
            }
        }
    }
}
