
        @import 'styles/mixins.scss';
    
.languages {
  flex-wrap: wrap;
}
.languages > a {
  flex: 0 0 20%;
  margin-bottom: 20px;
  color: #000;
  text-decoration: none;
}
.language {
  transition: transform 0.1s ease-in-out;
  &:hover {
    transform: scale(1.1);
  }
}
