
        @import 'styles/mixins.scss';
    
.genericForm {
    background: #f2f8ff;
    box-shadow: (0px 9px 18px rgba(0, 0, 0, 0.2));
}
.btnWidth {
    @media screen and (min-width: 768px) {
        max-width: 245px;
    }
}
